@media screen and (max-width: 910px) {
  .default-content {
    .center {
      max-width: 90%;
      margin-left: 5%;
      margin-right: 5%;
    }
  }

  .siteHeader {
    padding-left: 5px;
    padding-right: 5px;
    height: 100px;

    &__Logo {
      max-width: 100%;
      width: 100%;
      text-align: center;
      height: 50px;
      line-height: 50px;

      img {
        max-height: 30px;
      }
    }

    &__Nav {
      position: static;
      width: 100%;
      white-space: nowrap;
      overflow-x: scroll;
      height: 50px;

      ul {
        display: block;

        li {
          position: relative;
          white-space: nowrap;

          a {
            padding: 0 5px;
            line-height: 50px;
          }
        }
      }
    }
  }

  .js-SiteHeader--up {
    top: -85px;
  }

  .intro {
    margin-top: 0;

    &__Slider {
      //height: 100vh;
      height: 245px;
      margin-top: 100px;
      .flickity-prev-next-button {
        display: none;
      }
    }

    &__Slide {
      //height: 100vh;
      height: 245px;
      background-size: 100% auto;
    }

    &__Inner {
      bottom: auto;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      max-width: 100%;
      width: 100%;
      padding-left: 30px;
      padding-right: 30px;
      border-bottom: 0;
    }

    h2 {
      font-size: 35px;
    }

    a {
      position: relative;
      bottom: auto;

      &:before {
        display: none;
      }
    }
  }

  .movies {
    padding: 30px;

    &__Filters {
      button {
        width: 40%;
        margin-bottom: 5%;
        height: 60px;
        padding: 0;
      }

      input {
        width: 40%;
        margin-bottom: 5%;
        height: 60px;
        padding: 0;
      }
    }

    &__Box {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 10%;

      &--cnt {
        height: auto;

        a {
          height: auto;
        }

        h2 {
          color: $brand;
        }

        h5 {
          color: $brand2;
          opacity: 1;
        }

        h6 {
          color: $brand;
          opacity: 1;
          position: relative;
          bottom: auto;
          right: auto;
        }
      }

      &--img {
        margin-bottom: 20px;
        height: 360px;
      }

      &--titles {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
      }
    }
  }

  .cta {
    height: auto;
    padding-top: 50px;
    padding-bottom: 50px;

    .center {
      height: auto;
    }

    h2 {
      font-size: 28px;
    }

    &__Content {
      top: auto;
      transform: translateY(0);
      max-width: 100%;
      padding-left: 30px;
      padding-right: 30px;
    }

    &__Contact {
      top: auto;
      transform: translateY(0);
      width: 100%;
      max-width: 100%;
      padding-left: 30px;
      padding-right: 30px;
      float: left;

      a {
        width: 100%;
        height: 70px;
        line-height: 70px;
      }
    }
  }

  .now-showing {
    &__Box {
      margin-bottom: 60px;
      padding-left: 30px;
      padding-right: 30px;
    }

    &__Image {
      width: 100%;
    }

    &__Cnt {
      width: 100%;
    }
  }

  .contact {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;

    li {
      display: block;
      width: 100%;
      border-left: 0;
      padding-left: 0;
      padding-top: 15px;
      padding-bottom: 15px;
      text-align: center;
    }
  }

  .form {
    padding-left: 30px;
    padding-right: 30px;

    &__Wrap1 {
      width: 100%;
      margin-right: 0;
    }

    &__Wrap2 {
      width: 100%;
      margin-left: 0;
    }

    input[type="submit"] {
      width: 100%;
    }
  }

  .siteFooter {
    &__Block {
      width: 100%;
      text-align: center;
      margin-right: 0;

      &:last-of-type {
        text-align: center;
        margin-top: 10px;
      }
    }
  }
}
