.siteHeader {float: left;width: 100%;height: 70px;background: $gray;z-index: 1000000;position: fixed;top: 0;transition: top 0.3s ease-in-out;
  &:before {content: '';position: absolute;top: 0;right: 0;bottom: 0;left: 0;background: $gray;opacity: 0;transition:300ms;}
  &__Logo {line-height: 70px;opacity: 1;transition:300ms;float: left;
    img {max-height: 40px;vertical-align: middle;}
  }
  &__Nav {
    position: relative;float: right;height: 70px;
    ul {padding: 0;margin: 0;display: inline-block;
      li {list-style: none;display: inline-block;
        a {font-weight: bold;color: $brand2;text-decoration: none;padding: 0 15px;line-height: 70px;display: block;position: relative;transition:400ms;
          text-transform: uppercase;
          &:hover {color: $brand;}
        }
      }

    li.current-menu-item {
          a {
            color: $brand2;
          
        }
      }
    }
    .wpml-ls-legacy-list-horizontal {
      padding: 0 !important;
    }
  }
}
.js-SiteHeader {
  &--up {top: -70px;}
  &--down {top: 0px;
    &:before {opacity: 1;}
  }
  &--marginer {top: 0px;
    &:before {opacity: 0;}
  }
}
.wpml-ls-link {
  color: $brand !important;
}
.wpml-ls-legacy-list-horizontal a span {
  vertical-align: top !important;
}