.intro {
  float: left;
  width: 100%;
  position: relative;
  z-index: 1;
  text-align: center;
  margin-top: 50px;

  h2 {
    font-size: 45px;
    color: $white;
    margin: 0;
    font-weight: normal;
    line-height: 57px;
    letter-spacing: 0;
    margin-bottom: 10px;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.8);

    strong {
      display: block;
    }
  }

  p {
    font-family: $font2;
    color: $black2;
    margin: 0;
    text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.8);
  }

  a {
    text-decoration: none;
    font-size: 14px;
    text-transform: uppercase;
    color: $white;
    position: absolute;
    bottom: 45px;
    right: 0;
    display: block;
    z-index: 1;
    font-weight: 700;

    &:before {
      content: '';
      left: 0;
      right: 0;
      bottom: -2px;
      height: 2px;
      position: absolute;
      background: $white;
      z-index: -1;
      transition: 300ms;
    }

    &:hover {
      &:before {
        background: $brand;
      }
    }
  }

  &__Slider {
    float: left;
    width: 100%;
    height: 80vh;
    position: relative;

    &--normal-image {
      opacity: 1 !important;
      height: 60vh;
      margin-bottom: 50px;
    }

    .flickity-prev-next-button {
      background: transparent;
      border: 0;

      svg {
        max-width: 20px;

        path {
          fill: $white;
          transition: 300ms;
        }
      }

      &:hover {
        svg {
          path {
            fill: $brand;
          }
        }
      }
    }

    .flickity-prev-next-button.previous {
      position: absolute;
      left: 50% !important;
      top: auto !important;
      transform: translateX(-580px) !important;
      bottom: 25px;
    }

    .flickity-prev-next-button.next {
      position: absolute;
      left: 50% !important;
      top: auto !important;
      transform: translateX(-550px) !important;
      bottom: 25px;
    }
  }

  &__Slide {
    float: left;
    width: 100%;
    height: 80vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    z-index: 1;

    &--normal-image {
      opacity: 1 !important;
      height: 60vh;
    }
  }

  &__Inner {
    max-width: 1160px;
    width: 100%;
    margin: 0 auto;
    left: 50%;
    transform: translateX(-50%);
    text-align: left;
    padding-bottom: 30px;
    border-bottom: 1px solid $white;
    bottom: 100px;
    position: absolute;
  }
}

/* Fade CSS */

.flickity-slider {
  transform: none !important;
}

.intro__Slide {
  left: 0 !important;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  z-index: -1;
}

.intro__Slide.is-selected {
  opacity: 1;
  z-index: 0;
}

.movies {
  float: left;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
  background: $gray;

  h2 {
    font-size: 28px;
    color: $brand2;
    margin: 0;
    font-weight: 700;
    letter-spacing: 0;
    margin-bottom: 10px;
    text-align: center;
  }

  h3 {
    color: $brand;
    margin: 0;
    text-align: center;
    margin-bottom: 60px;
  }

  &__Filters {
    float: left;
    width: 100%;
    text-align: center;
    position: relative;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      margin: auto;
      left: 50%;
      transform: translateX(-50%);
      position: relative;
      float: left;
    }

    li {
      float: left;
      margin-right: 15px;
    }

    button {
      padding: 10px 30px;
      letter-spacing: 0;
      font-weight: 700;
      color: $white;
      background: $brand;
      text-decoration: none;
      display: inline-block;
      cursor: pointer;
      border-radius: 5px;
      transition: 300ms;
      text-transform: uppercase;
      border: 0;

      &:hover {
        background: $brand2;
      }
    }

    input {
      padding: 9px 10px;
      letter-spacing: 0;
      font-weight: 700;
      color: $brand;
      border: 1px solid #763a7a;
      text-decoration: none;
      display: inline-block;
      border-radius: 5px;
      background: transparent;
      text-align: center;

      &:focus {
        outline: none;
      }
    }

    .mixitup-control-active {
      background: $brand2;
    }

    .hide-button {
      display: none !important;
    }
  }

  &__Content {
    float: left;
    width: 100%;
    margin-top: 80px;
  }

  &__Box {
    margin-bottom: 0.8%;
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    margin-left: 0.4%;
    margin-right: 0.4%;
    float: left;
    display: block;
    width: 24%;

    &--img {
      height: 280px;
      float: left;
      width: 100%;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      position: relative;
      z-index: 1;
      overflow: hidden;
      transition: 300ms;
      z-index: 1;
      position: relative;
      border-radius: 5px;
      overflow: hidden;
      cursor: pointer;

      &:before {
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: $black;
        opacity: 0.2;
        z-index: -1;
        position: absolute;
        transition: 300ms;
      }
    }

    &--titles {
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 10;
      right: 20px;
    }

    &--cnt {
      position: relative;
      height: 280px;
      width: 100%;
      float: left;
      overflow: hidden;
      cursor: pointer;

      h2 {
        font-family: $font2;
        color: $gray;
        margin: 0;
        line-height: 1;
        z-index: 10;
        margin-bottom: 10px;
        text-align: left;
      }

      h5 {
        z-index: 10;
        color: $white;
        margin: 0;
        opacity: 0;
        transition: 300ms;
        font-weight: bold;

        span {
          margin-right: 10px;
          display: block;
        }
      }

      h6 {
        position: absolute;
        right: 25px;
        bottom: 25px;
        color: $white;
        z-index: 10;
        font-family: $font2;
        margin: 0;
        opacity: 0;
        transition: 300ms;
        font-weight: bold;
      }

      a {
        text-decoration: none;
        float: left;
        height: 280px;
        width: 100%;
      }

      &:hover {
        .movies__Box--img {
          width: 110%;
          margin-left: -5%;

          &:before {
            opacity: 0.7;
          }
        }

        h5 {
          opacity: 1;
        }

        h6 {
          opacity: 1;
        }
      }
    }
  }
}

.contact {
  float: left;
  width: 100%;
  background: $brand;
  padding: 40px 0;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    float: left;
    text-align: left;
    width: 25%;
    padding-left: 20px;
    border-left: 1px solid $gray;
  }

  h3 {
    margin-top: 0;
    color: $white;
    margin-bottom: 5px;
  }

  a {
    color: $gray;
    text-decoration: none;
    font-size: 13px;
  }
}

.controls {
  &__box {
    margin-bottom: 15px;
  }
}

.cta {
  float: left;
  width: 100%;
  height: 600px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 1;
  position: relative;
  overflow: hidden;

  .center {
    height: 600px;
  }

  &__Content {
    max-width: 400px;
    top: 50%;
    transform: translateY(-50%);
    position: relative;
    float: left;
  }

  &__Contact {
    max-width: 400px;
    top: 50%;
    transform: translateY(-50%);
    position: relative;
    float: right;
  }

  h2 {
    font-size: 36px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 1;
    color: $white;
  }

  a {
    color: $brand;
    background: $white;
    text-decoration: none;
    display: inline-block;
    letter-spacing: 0;
    font-weight: 700;
    border-radius: 5px;
    width: 300px;
    height: 150px;
    text-transform: uppercase;
    line-height: 150px;
    text-align: center;
    transition: 300ms;

    &:hover {
      background: $brand;
      color: $white;
    }
  }
}

.now-showing {
  float: left;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
  background: $gray;

  &__Title {
    font-size: 36px;
    color: $brand2;
    margin: 0;
    font-weight: 700;
    letter-spacing: 0;
    margin-bottom: 10px;
    text-align: center;
  }

  &__Subtitle {
    color: $brand;
    margin: 0;
    text-align: center;
    margin-bottom: 60px;
  }

  &__Box {
    width: 100%;
    float: left;
    margin-bottom: 60px;
  }

  &__Image {
    height: 300px;
    width: 70%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    float: left;
    position: relative;
    z-index: 10;

    h3 {
      color: $white;
      margin: 0;
      position: absolute;
      top: 30px;
      left: 30px;
      font-size: 30px;
      font-weight: bold;
    }

    h5 {
      color: $white;
      margin: 0;
      position: absolute;
      top: 70px;
      left: 30px;
      opacity: 0;
      transition: 300ms;
      font-weight: bold;

      span {
        display: block;
      }
    }

    img {
      max-height: 80px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      transition: 300ms;
    }

    a {
      text-decoration: none;
      width: 100%;
      height: 300px;
      float: left;
      display: block;
      position: relative;

      &::before {
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        position: absolute;
        background: $black;
        opacity: 0;
        z-index: -1;
        transition: 300ms;
      }

      &:hover {
        h5 {
          opacity: 1;
        }

        img {
          opacity: 1;
        }

        &:before {
          opacity: 0.6;
        }
      }
    }
  }

  &__Cnt {
    float: left;
    width: 30%;
    position: relative;
    height: 300px;
    padding: 30px;
    z-index: 1;

    p {
      font-weight: lighter;
      font-size: 14px;
    }

    a {
      text-decoration: none;
      font-size: 14px;
      text-transform: uppercase;
      color: $brand;
      position: absolute;
      bottom: 30px;
      right: 30px;
      display: block;
      z-index: 1;
      font-weight: 700;

      &:before {
        content: '';
        left: 0;
        right: 0;
        bottom: -2px;
        height: 2px;
        position: absolute;
        background: $brand;
        z-index: -1;
        opacity: 0;
        transition: 300ms;
      }

      &:after {
        content: '';
        left: -20px;
        bottom: -2px;
        height: 3px;
        position: absolute;
        background: $gray;
        z-index: -1;
        width: 20px;
        transition: 300ms;
        transition-delay: 300ms;
      }

      &:hover {
        &:before {
          opacity: 1;
        }

        &:after {
          transform: translateX(160px);
        }
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-right: 1px solid $brand2;
      border-bottom: 1px solid $brand2;
      left: 50%;
      z-index: -1;
    }
  }
}

.form {
  float: left;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
  background: $gray;

  h2 {
    font-size: 36px;
    color: $brand2;
    margin: 0;
    font-weight: 700;
    letter-spacing: 0;
    margin-bottom: 10px;
    text-align: center;
  }

  h3 {
    color: $brand;
    margin: 0;
    text-align: center;
    margin-bottom: 60px;
  }

  p {
    max-width: 670px;
    margin-left: auto;
    margin-right: auto;
    color: $brand2;
  }

  ul {
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
  }

  li {
    list-style: none;
    font-family: $font2;
    color: $brand2;
  }

  h6 {
    font-family: $font2;
    font-size: 16px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.69;
    letter-spacing: normal;
    text-transform: uppercase;
    margin-bottom: 20px;
    color: $dark;
    float: left;
    width: 100%;
  }

  input[type='text'] {
    float: left;
    width: 100%;
    background: transparent;
    border: 0;
    height: 50px;
    line-height: 50px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
    color: $brand2;
    letter-spacing: 0;
    border-bottom: 2px solid $brand2;
    font-family: $font2;
  }

  input[type='email'] {
    float: left;
    width: 100%;
    background: transparent;
    border: 0;
    height: 50px;
    line-height: 50px;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
    color: $brand2;
    letter-spacing: 0;
    border-bottom: 2px solid $brand2;
    font-family: $font2;
  }

  textarea {
    float: left;
    min-width: 100%;
    max-width: 100%;
    width: 100%;
    background: transparent;
    border: 0;
    padding: 10px;
    margin-bottom: 10px;
    min-height: 200px;
    color: $brand2;
    letter-spacing: 0;
    border-bottom: 2px solid $brand2;
    font-family: $font2;
  }

  input[type='submit'] {
    width: 300px;
    height: 60px;
    line-height: 60px;
    color: $white;
    background: $brand;
    text-decoration: none;
    display: inline-block;
    letter-spacing: 0;
    font-weight: 700;
    margin: auto;
    border: 0;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    transition: 300ms;
    text-transform: uppercase;

    &:hover {
      background: $brand2;
    }
  }

  &__Wrap1 {
    float: left;
    width: 30%;
    margin-right: 5%;
    padding-top: 10px;
  }

  &__Wrap2 {
    float: left;
    width: 60%;
    margin-left: 5%;
  }

  &__Wrap3 {
    max-width: 670px;
    margin: auto;
  }
}

.default-content {
  padding-bottom: 100px;

  .center {
    max-width: 640px;
  }

  strong {
    font-weight: bold;
  }

  p {
    display: block;
    margin-bottom: 10px;
  }

  h1 {
    font-size: 30px;
    margin-bottom: 10px;
  }

  h2 {
    font-size: 28px;
    margin-bottom: 10px;
  }

  h3 {
    font-size: 26px;
    margin-bottom: 10px;
  }

  h4 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  h5 {
    font-size: 22px;
    margin-bottom: 10px;
  }

  h6 {
    font-size: 20px;
    margin-bottom: 10px;
  }
}
.single-post-content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  a {
    color: $dark;
  }
  p {
    line-height: 1.6;
  }
  blockquote {
    background: #ededed;
    padding: 20px;
    p {
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
  .gallery-item {
    img {
      border: 0 !important;
    }
  }
}
.lightboxOverlay {
  z-index: 1000000 !important;
}
.lightbox {
  z-index: 10000000 !important;
}
.news {
  float: left;
  width: 100%;
}
.news-blocks {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.news-block {
  margin-bottom: 60px;
  width: 32%;
  .image {
    position: relative;
    overflow: hidden;
    height: 240px;
    //border-radius: 10px;
    margin-bottom: 15px;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      transition: 300ms;
    }
  }
  &--big {
    width: 49%;
    .image {
      height: 380px;
    }
  }
  &__text {
    h4 {
      font-family: Montserrat, sans-serif;
      color: $white;
      font-size: 22px;
      font-weight: 700;
      margin: 0 0 15px;
      transition: 300ms;
      position: relative;
      //padding: 5px 10px;
      display: inline-block;
      //transform: rotate(-0.5deg);
      span {
        display: inline;
        background: $brand;
        padding: $padding;
        padding-left: 0;
        padding-right: 0;
        -webkit-box-decoration-break: clone;
        -ms-box-decoration-break: clone;
        -o-box-decoration-break: clone;
        box-decoration-break: clone;
        box-shadow: $padding 0 0 $brand, -$padding 0 0 $brand;
        margin-left: 0.5rem;
        line-height: 2.2;
        transition: 300ms;
      }
    }
    p {
      font-family: Montserrat, sans-serif;
      color: $dark;
      font-size: 16px;
      margin: 0;
    }
  }
  &:hover {
    img {
      opacity: 0.8;
      transform: scale(1.1);
    }
    h4 {
      span {
        background: $dark;
        box-shadow: $padding 0 0 $dark, -$padding 0 0 $dark;
      }
    }
  }
}
@media screen and (max-width: 910px) {
  .news-blocks {
    display: block;
  }
  .news-block {
    display: block;
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
    &--big {
      width: 60%;
      margin-left: 20%;
      margin-right: 20%;
      .image {
        height: 240px;
      }
    }
  }

  .gallery {
    .gallery-item {
      width: 50% !important ;
      float: none !important;
      margin: 10px auto 0 !important;
      a {
        width: 100% !important;
      }
      img {
        width: 100% !important;
        max-width: 100% !important;
        height: auto !important;
        object-fit: cover !important;
      }
    }
  }
}

.navigation {
  width: 100%;
  text-align: center;
  float: left;
  margin-bottom: 100px;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      display: inline-block;
      a {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ededed;
        color: $dark;
        transition: 300ms;
      }
      &.active,
      &:hover {
        a {
          color: #ededed;
          background: $dark;
        }
      }
    }
  }
}
