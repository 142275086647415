// COLORS
$brand: #763a7a;
$brand2: #7c7c7c;

$white: #fff;
$black: #000;
$black2: #1b1919;
$gray: #f9f9f9;
$dark: #292929;
$text: #505050;
$gold: #9e8157;
$facebook: #3b5997;
$twitter: #4099ff;
$google: #c63d2d;

$padding: 0.5rem;

@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700,900&subset=latin-ext');

$font-size: 16px;
$line-height: 1.5;
$font1: 'Montserrat', sans-serif;
$font2: 'Montserrat', sans-serif;
