.siteFooter {
  float: left;
  width: 100%;
  background: $black2;
  padding-top: 20px;
  padding-bottom: 20px;

  &__Block {
    float: left;
    display: block;
    margin-right: 0.86655%;
    width: 49.56672%;

    &:last-of-type {
      margin-right: 0;
    }

    span {
      display: inline-block;
      color: $white;
      font-weight: lighter;
      font-size: 13px;

      a {
        text-decoration: none;
        color: $white;
        transition: 300ms;
        font-weight: lighter;

        &:hover {
          color: $gold;
        }
      }
    }

    a {
      text-decoration: none;
    }

    &:last-of-type {
      text-align: right;
    }
  }
}
